:root {
    --primary: #005640;
    --secondary: #4c9c2e;
    --info: #0080ff;
    --infohover: #0e5dac;
    --grey: #808080;
}
*,
*::before,
*::after {
    box-sizing: border-box;
}
body::-webkit-scrollbar {
    width: 5px;
}
body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}
body::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 10px;
}
body::-webkit-scrollbar-thumb:hover {
    background: var(--secondary);
}
a {
    color: #022648;
    text-decoration: none;
    background-color: transparent;
}

/* Buttons */
.btn {
    display: inline-block;
    font-weight: 500;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    width: 100px;
    border-radius: 5px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-size: 14px;
    line-height: 14px;
    height: 40px;
    font-family: primaryfontmedium;
}
@media screen and (prefers-reduced-motion: reduce) {
    .btn {
        transition: none;
    }
}
.btn:hover {
    text-decoration: none;
}
.btn:focus,
.btn.focus {
    outline: 0;
    box-shadow: none;
}
.btn.disabled,
.btn:disabled {
    opacity: 0.65;
    cursor: not-allowed;
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.btn_primary {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}
.btn_primary:hover {
    color: #fff;
    background-color: var(--secondary);
    border-color: var(--secondary);
}
.btn_primary.disabled,
.btn_primary:disabled {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
    cursor: not-allowed;
    opacity: 0.7;
}
.btn_winner {
    width: 150px;
    height: 50px;
    background-color: #389071 !important;
    color: #fff;
    border-color: #389071;
}
.btn_winner:hover {
    color: #fff;
    background-color: var(--secondary) !important;
    border-color: var(--secondary) !important;
}
.btn_winner.disabled,
.btn_winner:disabled {
    color: #fff;
    background-color: #329372 !important;
    border-color: #329372 !important;
    cursor: not-allowed;
    opacity: 0.7;
}
.btn_secondary {
    color: #fff;
    background-color: var(--secondary);
    border-color: var(--secondary);
}
.btn_secondary:hover {
    color: #fff;
    background-color: var(--secondaryhover);
    border-color: var(--secondaryhover);
}
.btn_secondary.disabled,
.btn_secondary:disabled {
    color: #fff;
    background-color: var(--secondary);
    border-color: var(--secondary);
    cursor: not-allowed;
    opacity: 0.7;
}
.btn_success {
    background-color: #18d26b;
    color: #fff;
}
.btn_success:hover {
    background-color: #0eb057;
    color: #fff;
}
.btn_danger {
    background-color: #ff3f3f;
    color: #fff;
}
.btn_danger:hover {
    background-color: #901212;
    color: #fff;
}
.btn_info {
    background-color: var(--info);
    color: #fff;
}
.btn_info:hover {
    background-color: var(--infohover);
    color: #fff;
}

/* Inner Content */
.app_container {
    min-height: 100%;
    width: 100% !important;
    margin: 0;
    background-color: #fff;
}
.app_main {
    z-index: 8;
    position: relative;
}
.app_main_inner {
    flex: 1;
}

.subscribebtn {
    background: rgb(29, 134, 73);
    background: linear-gradient(90deg, rgba(29, 134, 73, 1) 0%, rgba(72, 188, 34, 1) 100%);
    width: 280px;
    height: 55px;
    border-radius: 13px;
    color: white;
    font-size: 20px;
    box-shadow: 0 0 7px rgb(57, 57, 57);
    transition: 0.3s !important;
    font-family: primaryfontbold;
}
.subscribebtn:hover {
    transform: scale(1.1) !important;
}
.card {
    background-color: white;
    border-radius: 20px;
}
.formcontainer {
    width: 30%;
    background-color: white;
    border-radius: 15px;
    min-height: 500px;
    box-shadow: 1px 15px 26px 0px #d1d1d1;

    margin: auto;
}
.loginformcontainer {
    min-height: 400px;
}
.subscribeformcontainer {
    width: 28%;
}
@media screen and (max-width: 800px) {
    .formcontainer {
        width: 90%;
    }
    .subscribeformcontainer {
        width: 85%;
    }
}
/* header */
.headerWrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    align-content: center;
    position: relative;
    z-index: 10;
    transition: all 0.2s;
    z-index: 1000;
    width: 100% !important;
    background-color: white;
    padding: 20px 20px;
    border-radius: 0 0 35px 35px;
}
.logo {
    max-width: 15% !important;
    max-height: 15% !important;
}
.visionlogo {
    max-width: 8% !important;
    max-height: 8% !important;
}
.logo2 {
    max-width: 65% !important;
    max-height: 65% !important;
}
.visionlogo2 {
    max-width: 60% !important;
    max-height: 60% !important;
}
@media screen and (max-width: 600px) {
    .logo {
        max-width: 40% !important;
        max-height: 40% !important;
    }
    .visionlogo {
        max-width: 30% !important;
        max-height: 30% !important;
    }
    .logo2 {
        max-width: 50 !important;
        max-height: 50% !important;
    }
    .visionlogo2 {
        max-width: 55% !important;
        max-height: 55% !important;
    }
}
/* Form */
.form_control {
    display: block;
    width: 100%;
    height: 40px;
    font-size: 1rem;
    font-weight: 400;
    color: #000;
    background-color: #fff;
    border-radius: 13px;
    font-size: 14px;
    transition: background 0s ease-out;
    border: 0;
    padding: 0 15px;
    box-shadow: 0px 0px 13px 0px #e9e9e9;

    /* box-shadow: 0 0 10px #ccc; */
}
.form_control2 {
    box-shadow: none;
    border: 1px solid #8a98ac;
    border-radius: 20px;
    height: 40px;
    width: 100%;
    padding: 0 7px;
}
.pagination_form_control {
    box-shadow: none !important;
    border: 1px solid #ccc;
    width: 50px;
    border-radius: 0;
}
@media screen and (prefers-reduced-motion: reduce) {
    .form_control {
        transition: none;
    }
}
.form_control::-ms-expand {
    background-color: transparent;
    border: 0;
}
.form_control::placeholder {
    color: rgb(91, 91, 91);
    font-weight: 500;
    font-size: 15px;
}
.form_control:disabled,
.form_control[readonly] {
    /* background-color: #e9ecef; */
    opacity: 1;
    cursor: not-allowed;
}
.form_control:focus {
    transition-duration: 0.3s;
    /* box-shadow: none; */
}
.form_control::-moz-placeholder {
    color: #000;
    font-weight: 500;
    font-size: 15px;
}
.form_control:-ms-input-placeholder {
    color: #000;
    font-weight: 500;
    font-size: 15px;
}
.form_control::-webkit-input-placeholder {
    color: #000;
    font-weight: 500;
    font-size: 15px;
}
.blackplaceholder::placeholder {
    color: #000;
    opacity: 1;
    font-weight: 600;
    font-size: 15px;
}
.blackplaceholder::-webkit-input-placeholder {
    color: #000 !important;
}
.blackplaceholder:disabled {
    color: #000 !important;
    opacity: 1 !important;
}
.lightplaceholder {
    color: rgb(238, 228, 228);
    opacity: 1;
    font-weight: 500;
    font-size: 16px;
}
.form_control.disabled,
.form_control:disabled,
.form_control[disabled] {
    cursor: not-allowed;
    /* background-color: red !important; */
}
.formbtn {
    width: 85%;
    height: 55px;
    border-radius: 20px;
    color: white;
    background: rgb(118, 183, 132);
    background: linear-gradient(90deg, rgba(118, 183, 132, 1) 0%, rgba(166, 216, 102, 1) 100%);
    transition: 0.3s;
}
.formbtn2 {
    width: 85%;
    height: 55px;
    border-radius: 20px;
    color: white;
    background: rgb(118, 183, 132);
    background: linear-gradient(90deg, #258b43 0%, #82ca0b 100%);
    transition: 0.3s;
}

.formbtn:hover {
    transform: scale(1.1);
}

.sidenav {
    height: 100vh !important;
    /* position: fixed; */
    background-color: white;
    width: 100%;
}
.sidenavlogo {
    max-width: 70%;
    max-height: 70%;
}
.activepage {
    color: var(--primary) !important;
}
.sidenavpage {
    font-family: primaryfontmedium !important;
}
.sidenavpage:hover {
    cursor: pointer !important;
    transition: 0.3s !important;
    color: var(--secondary) !important;
}
.sidenavatar {
    max-width: 7%;
    max-height: 7%;
}
@media (max-width: 991.98px) {
    .app_sidebar {
        width: 100% !important;
        transform: translateX(-100%);
        position: fixed;
        background-color: #f2f2f2;
        border-right: 2px solid #ccc;
        margin-top: 0;
        height: 90vh;
        overflow-y: scroll !important;
    }
    .sidebar_mobile_open {
        transform: translateX(0);
    }
}
@media (max-width: 700px) {
    .sidenavatar {
        max-width: 30%;
        max-height: 30%;
    }
}
@media (max-width: 600px) {
    .sidenavatar {
        max-width: 30%;
        max-height: 30%;
    }
}
.sponsor {
    max-width: 30% !important;
    max-height: 30% !important;
}
@media (max-width: 600px) {
    .sponsor {
        max-width: 100% !important;
        max-height: 100% !important;
    }
}
.form_btn {
    width: 200px;
    height: 45px;
    color: #fff;
    background: rgb(29, 134, 73);
    background: linear-gradient(90deg, rgba(29, 134, 73, 1) 0%, rgba(72, 188, 34, 1) 100%);
    box-shadow: 0 0 7px rgb(57, 57, 57);
    transition: 0.3s;
    font-family: primaryfontmedium;
}
.form_btn:hover {
    transform: scale(1.1) !important;
}
.form_control_noshadow {
    box-shadow: none !important;
}
.logowinner {
    position: absolute !important;
    bottom: 20px !important;
    right: 20px !important;
    max-width: 10% !important;
}
.visionlogowinner {
    position: absolute !important;
    bottom: 20px !important;
    right: 200px !important;
    max-width: 6% !important;
}
@media (max-width: 800px) {
    .logowinner {
        max-width: 20% !important;
    }
    .visionlogowinner {
        max-width: 10% !important;
        right: 200px !important;
        bottom: 25px !important;
    }
}
@media (max-width: 600px) {
    .logowinner {
        max-width: 30% !important;
    }
    .visionlogowinner {
        max-width: 20% !important;
        right: 160px !important;
    }
}
.lotterybackbtn {
    position: absolute !important;
    top: 30px !important;
    right: 30px !important;
    width: 5.5vh !important;
    height: 5.5vh !important;
    border-radius: 100px !important;
    z-index: 1000 !important;
    background-color: white !important;
    color: #000 !important;
}
.lotterybackbtn:hover {
    transition: 0.3s !important;
    cursor: pointer !important;
    background-color: var(--secondary) !important;
    color: #fff !important;
}
@media screen and (max-width: 600px) {
    .lotterybackbtn {
        width: 5vh !important;
        height: 5vh !important;
        top: 20px !important;
        right: 20px !important;
    }
}
.tweet_container {
    margin-top: 2rem;

    box-shadow: 0 2px 5px rgba(50, 120, 80, 0.1);
    display: flex;
    background-color: #ffffff;
    align-items: center;
    justify-content: center;
    color: #000;
    background-color: #fff;
    border-radius: 13px;

    /* flex: 4 2 2; */
}

.copyicon:focus {
    outline: none;
}
